const clients = {
  admin: {
    name: 'Admin Site',
    accountType: 'admin',
    callbacks: [
      'http://localhost:3000',
      'https://admin-dev.stargazer.co',
      'https://admin-staging.stargazer.co',
      'https://admin.stargazer.co',
    ],
  },
  brand: {
    name: 'Brand Site',
    accountType: 'client',
    callbacks: [
      'http://localhost:3001',
      'https://brand-dev.stargazer.co',
      'https://brand-staging.stargazer.co',
      'https://brand.stargazer.co',
    ],
  },
  creator: {
    name: 'Creator Site',
    accountType: 'influencer',
    callbacks: [
      'http://localhost:3002',
      'https://creator-dev.stargazer.co',
      'https://creator-staging.stargazer.co',
      'https://creator.stargazer.co',
    ],
  },
  ai: {
    name: 'Ai Site',
    accountType: 'influencer',
    callbacks: [
      'http://localhost:3006',
      'https://ai-dev.stargazer.co',
      'https://ai-staging.stargazer.co',
      'https://ai.stargazer.co',
    ],
  },
  search: {
    name: 'Search Site',
    accountType: 'admin',
    callbacks: [
      'http://localhost:3003',
      'https://search-dev.stargazer.co',
      'https://search-staging.stargazer.co',
      'https://search.stargazer.co',
    ],
  },
  companion: {
    name: 'Companion Extension',
    accountType: 'admin',
    callbacks: ['https://www.stargazer.co/'],
  },
};

export { clients };
