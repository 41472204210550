import React from 'react';
import SignedInForm from './SignedInForm';
import SignInForm from './SignInForm';
import AuthPage from '../../src/components/AuthPage';
import { Card } from '@mui/material';
import LoadingView from '@stargazer/core-ui-v2/src/components/core/LoadingView';
import ErrorView from '@stargazer/core-ui-v2/src/components/ErrorView';

const SignInView = ({
  loading,
  client,
  user,
  forgotUrl,
  signupUrl,
  signoutUrl,
  cryptoSignInUrl,
  onSignin,
  // formik props
  values = {},
  errors = {},
  status = {},
  touched = {},
  isValid,
  handleChange,
  handleBlur,
  handleSubmit,
  isSubmitting = false,
  submitForm,
}) => {
  if (loading) {
    return (
      <AuthPage fullWidth logoType="long" title="Signed In" backgroundImage={true}>
        <LoadingView title="Loading" />
      </AuthPage>
    );
  }

  if (!client) {
    return (
      <AuthPage fullWidth justifyContent="center" logoType="long" title="Signed In" backgroundImage={true}>
        <ErrorView message="Invalid client configuration" />
      </AuthPage>
    );
  }

  if (user) {
    return (
      <AuthPage justifyContent="end" logoType="long" title="Signed In" backgroundImage={true}>
        <Card
          sx={{
            width: '100%',
            boxShadow: '0px 16px 32px rgba(0, 0, 0, 0.16)',
            backgroundColor: 'rgba(10,10,10,.5)',
            borderRadius: '16px',
          }}
        >
          <SignedInForm client={client} user={user} signoutUrl={signoutUrl} onSignin={onSignin} />
        </Card>
      </AuthPage>
    );
  }

  return (
    <SignInForm
      {...{
        forgotUrl,
        signupUrl,
        cryptoSignInUrl,
        // formik props
        values,
        errors,
        status,
        touched,
        isValid,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        submitForm,
      }}
    />
  );
};

export default SignInView;
