import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
//import logoGIF from '../../../assets/stargazer_splashscreen.gif';
import { Box } from '@mui/material';

const styles = {
  rootCenter: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    flexDirection: 'column',
    minHeight: '300px',
    padding: '20px',
  },
  LinearProgress: {
    width: '100%',
    height: '5px',
    margin: '30px',
  },
};

const LoadingView = ({ title = 'Loading', animation }) => (
  <Box sx={styles.rootCenter}>
    {animation ? (
      <Box
        component="img"
        sx={{
          height: 500,
          width: 500,
          maxHeight: { md: 500, xs: 350 },
          maxWidth: { md: 500, xs: 350 },
        }}
        alt="stargazerLogo."
        src={'/static/stargazer_splashscreen.gif'} //and move file to public/static/ on package that wanted to be used
        //src={logoGIF}
      />
    ) : (
      <>
        <Typography variant="h3" color="white" align="center">
          {title}...
        </Typography>
        <LinearProgress style={styles.LinearProgress} color="primary" />
        <Typography variant="bodyS_Highlight" color="white" align="center">
          please wait
        </Typography>
      </>
    )}
  </Box>
);

export default LoadingView;
