import { Box } from '@mui/material';
import React from 'react';

function Form({ noValidate, onSubmit, children }) {
  return (
    <Box component="form" onSubmit={onSubmit} noValidate={noValidate} sx={{ height: '100%' }}>
      {children}
    </Box>
  );
}

export default Form;
