import React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

const ProgressButton = ({ variant, onClick, disabled, loading, children, href }) => {
  const styles = {
    root: {
      zIndex: '1',
      position: 'relative',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    progress: {
      marginTop: '15px',
      marginBottom: '30px',
    },
  };

  return (
    <Box sx={styles.root}>
      {loading ? (
        <CircularProgress size={24} sx={styles.progress} />
      ) : (
        <Button href={href} variant={variant || 'contained'} disabled={disabled} onClick={onClick}>
          {children}
        </Button>
      )}
    </Box>
  );
};

export default ProgressButton;
