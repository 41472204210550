import React from 'react';
import AuthPage from '../../src/components/AuthPage';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ProgressButton from '@stargazer/core-ui-v2/src/components/core/ProgressButton';
import FormActions from '@stargazer/core-ui-v2/src/components/core/Form/FormActions';
import FormInputs from '@stargazer/core-ui-v2/src/components/core/Form/FormInputs';
import Link from 'next/link';
import MuiLink from '@mui/material/Link';
import Form from '../../src/components/Form';

const SignInForm = ({
  values,
  touched,
  errors,
  status,
  isValid,
  isSubmitting,
  forgotUrl,
  signupUrl,
  cryptoSignInUrl,
  handleChange,
  handleBlur,
  handleSubmit,
  submitForm,
}) => {
  return (
    <Form noValidate onSubmit={handleSubmit}>
      <AuthPage logoType="long" title="Sign In" backgroundImage={true}>
        <FormInputs>
          <Typography variant="h1" color="white">
            Sign In
          </Typography>

          <TextField
            variant="outlined"
            id="email"
            name="email"
            label="Email Address"
            margin="normal"
            required
            fullWidth
            autoComplete="email"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            onKeyDown={event => event.keyCode === 13 && submitForm()}
            error={errors.email && touched.email}
            helperText={errors.email && touched.email && errors.email}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
            onKeyDown={event => event.keyCode === 13 && submitForm()}
            error={errors.password && touched.password}
            helperText={errors.password && touched.password && errors.password}
          />

          {status && status.message && (
            <Typography variant="bodyS" color="error">
              {status.message}
            </Typography>
          )}
        </FormInputs>
        <FormActions>
          <ProgressButton disabled={!isValid} loading={isSubmitting} onClick={submitForm}>
            Sign In
          </ProgressButton>

          <Link href={cryptoSignInUrl} passHref>
            <ProgressButton variant="outlined" disabled={isSubmitting}>
              <Typography variant="primaryGradient"> Sign In With Crypto Wallet </Typography>
            </ProgressButton>
          </Link>
          <Grid container>
            <Grid item xs>
              <Link href={forgotUrl} passHref>
                <MuiLink variant="bodyS_Underline" underline="hover">
                  Forgot password?
                </MuiLink>
              </Link>
            </Grid>
            <Grid item>
              <Link href={signupUrl} passHref>
                <MuiLink variant="bodyS_Underline" underline="hover">
                  {"Don't have an account?"}
                </MuiLink>
              </Link>
            </Grid>
          </Grid>
        </FormActions>
      </AuthPage>
    </Form>
  );
};

export default SignInForm;
