import React, { useCallback } from 'react';
import { useAsync } from 'react-async';
import { useRouter } from 'next/router';
import { Formik } from 'formik';
import * as yup from 'yup';
import { clients } from '../../src/store';
import api from '../../src/lib/api';
import SignInView from './SignInView';
import getConfig from 'next/config';

const signInSchema = yup.object().shape({
  email: yup.string().email('Invalid email address').required('Required'),
  password: yup.string().min(7).required('Required'),
});

const { publicRuntimeConfig } = getConfig();

const SignIn = () => {
  const router = useRouter();
  const { client_id, redirect_url = '' } = router.query;
  const client = clients[client_id];
  const valid_callback_url = client && client.callbacks.find(url => redirect_url.startsWith(url));

  const {
    data: user,
    error,
    isPending: loading,
  } = useAsync({
    promiseFn: api.me,
  });

  const refreshTokenAndSignin = useCallback(async () => {
    const { user, token } = await api.refreshToken();
    const { redirect_url = '/' } = router.query;
    console.log(
      'redirecting to...',
      redirect_url.includes('?') ? `${redirect_url}&access_token=${token}` : `${redirect_url}?access_token=${token}`,
    );

    if (client_id === 'companion') {
      window.postMessage({ stargazer_access_token: token, api_url: publicRuntimeConfig.API_URL }, '*');
      setTimeout(() => {
        window.close();
      }, 1000);
    } else {
      window.location = redirect_url.includes('?')
        ? `${redirect_url}&access_token=${token}`
        : `${redirect_url}?access_token=${token}`;
    }
  }, [router]);

  const {
    data: token,
    error: tokenError,
    run: onSignin,
  } = useAsync({
    deferFn: refreshTokenAndSignin,
  });

  const onSubmit = async (values, actions) => {
    const { email, password } = values;
    try {
      const { user, token } = await api.signin({ email, password });
      const { redirect_url = '/' } = router.query;

      if (client_id === 'companion') {
        window.postMessage({ stargazer_access_token: token, api_url: publicRuntimeConfig.API_URL }, '*');
        setTimeout(() => {
          window.close();
        }, 1000);
      } else {
        window.location = redirect_url.includes('?')
          ? `${redirect_url}&access_token=${token}`
          : `${redirect_url}?access_token=${token}`;
      }
    } catch (error) {
      actions.setStatus({ message: error.message });
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <Formik initialValues={{ email: '', password: '' }} validationSchema={signInSchema} onSubmit={onSubmit}>
      {props => (
        <SignInView
          loading={loading}
          client={valid_callback_url && client}
          user={user}
          signupUrl={`/signup?client_id=${client_id}&redirect_url=${encodeURIComponent(redirect_url)}`}
          forgotUrl={`/forgot?client_id=${client_id}&redirect_url=${encodeURIComponent(redirect_url)}`}
          signoutUrl={`/signout?client_id=${client_id}&redirect_url=${encodeURIComponent(redirect_url)}`}
          cryptoSignInUrl={`/crypto-sign-in?client_id=${client_id}&redirect_url=${encodeURIComponent(redirect_url)}`}
          onSignin={onSignin}
          {...props}
        />
      )}
    </Formik>
  );
};

export default SignIn;
