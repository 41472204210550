import React from 'react';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ArrowRight from '@mui/icons-material/ArrowRight';
import ProgressButton from '@stargazer/core-ui-v2/src/components/core/ProgressButton';
import Link from 'next/link';

const styles = {
  centered: {
    justifyContent: 'center',
  },
  arrowRight: {
    marginLeft: '16px',
  },
  divider: {
    marginTop: '16px',
    marginBottom: '16px',
  },
};

const SignInForm = ({ client, user, signoutUrl, onSignin }) => {
  return (
    <List sx={{ color: 'white' }}>
      <ListItem disableGutters>
        <Typography>Proceed to {client.name} as:</Typography>
      </ListItem>
      <ListItem button onClick={onSignin}>
        <ListItemAvatar>
          <Avatar src={user.profilePicture} alt={`${user.firstname} ${user.lastname}`} />
        </ListItemAvatar>
        <ListItemText
          primary={`${user.firstname} ${user.lastname}`}
          secondary={<Typography variant="primaryGradient">{user.email}</Typography>}
        />

        <IconButton aria-label="continue" edge="end" sx={styles.arrowRight} size="large">
          <ArrowRight sx={{ fill: 'white' }} />
        </IconButton>
      </ListItem>

      <Divider />

      <ListItem disableGutters sx={styles.centered}>
        <Link href={signoutUrl} passHref>
          <ProgressButton variant="outlined">
            <Typography variant="primaryGradient"> Use a Different Account</Typography>
          </ProgressButton>
        </Link>
      </ListItem>

      <ListItem disableGutters sx={styles.centered}>
        <Link href={signoutUrl} passHref>
          <ProgressButton variant="outlined">
            <Typography variant="primaryGradient"> Sign out</Typography>
          </ProgressButton>
        </Link>
      </ListItem>
    </List>
  );
};

export default SignInForm;
